@import '../../variables.scss';

.wrapper{
    width: 100vw;
    max-width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(120deg, $rojo, $rosa);
    color: $blanco;
    h1{
        font-size: 8vmin;
        margin: 0;
        padding: 0;
    }
    p{
        font-size: 3.2vmin;
        margin: 0;
        padding: 0;
    }
}
