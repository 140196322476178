$cyan : #25CED1;
$blanco: #FFFFFF;
$rojo: #BA2D0B;
$naranja: #FF8A5B;
$rosa: #EA526F;

body{
    font-family: 'Gayathri', sans-serif;
}

.wrapperVista{
    width: 100vw;
    max-width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(120deg, $rojo, $rosa);
    color: $blanco;
    h1{
        position: absolute;
        top: 50vh;
        right: 2vw;
        text-align: center;
        font-size: 7vmin;
        margin: 0;
        padding: 0;
        transform: rotate(-90deg);
    }
    p{
        font-size: 3.2vmin;
        margin: 0;
        padding: 0;
    }
}
