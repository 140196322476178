@import '../../variables.scss';

.menuBoton{
    position: absolute;
    top: 48vh;
    left: 5vw;
    height: 5vh;
    width: 5vh;
    font-size: 3vmin;
    &:before{
        position: absolute;
        content:"Menu";
        right: 1.5vw;
        transform: rotate(-90deg);
        color: $blanco;
        transition: all .4s ease-in;
        font-weight: 600;
        
    }
    cursor: pointer;
    span{
        position: relative;
        display: flex;
        width: 5vmin;
        height: 1vmin;
        background: $blanco;
        border-radius: .5vmin;
        content: "";
        transition: all .3s ease-out, background .5s ease;
        &:nth-child(1){
            bottom: calc(.4vmin);
            left: calc(.8vmin);
            transform: rotate(45deg);
        }
        &:nth-child(3){
            top: calc(.4vmin);
            left:calc(.8vmin);
            transform: rotate(-45deg);
        }
    }
    &.cerrado{
        &:before{
            color: $cyan;
        }
        span{
            background: $cyan;
            &:nth-child(1){
                bottom: calc(.4vmin);
                left:calc(-.8vmin);
                transform: rotate(-45deg);
            }
            &:nth-child(3){
                top: calc(.4vmin);
                left:calc(-.8vmin);
                transform: rotate(45deg);
            }
        }
    }
}

.menu{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    top: 0;
    left: 0;
    width: 30vw;
    height: 100vh;
    background: $blanco;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    transition: all .5s ease-out;
    &.cerrado{
        left:-31vw;
        border-top-right-radius: 50vh;
        border-bottom-right-radius: 50vh;
    }
    a{
        position: relative;
        text-decoration: none;
        font-weight: 600;
        color: black;
        z-index: 200;
        margin: 5vmin 10vh;
        &.activo{
            display: none;
        }
        &::before{
            top: 0;
            left: 2vmin;
            width: 0vw;
            height: 0vw;
            border-radius: 1vw;
            content: "";
            background: $naranja;
            position: absolute;
            z-index: -1;
            transition: all .1s ease-out;
        }
        &:hover{
            &::before{
                top: -1vh;
                left: 0;
                width: 4vmin;
                height: 4vmin;
                border-radius: 2vmin;
            }
        }
    }
    ul{
        list-style: none;
        
        padding: 0;
        margin: 0;
        height: 100%;
        width: 20vw;
        li{
            height: 10vh;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            
        }
    }
}
