@import '../../variables.scss';

.contenedor{
    box-sizing: border-box;
    width: 50vw;
    height: 80vh;
    color: white;
    padding: 2vmin;
    overflow:hidden;
}

